body {
  margin:0;
  font-family: calibri;
}

/* header */


.header {
	background-color: #fff;
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
	position: fixed;
	width: 100%;
	z-index: 3;
}

.header ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	background-color: #fff;
}

.header li a {
	display: block;
	padding: 20px 20px;
	text-decoration: none;
	color: green;
	font-weight: 600;
}

.header li a:hover,
.header .menu-btn:hover {
	background-color: #f4f4f4;
}

.header .logo {
	display: block;
	float: left;
	font-size: 2em;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
}

/* menu */

.header .menu {
	clear: both;
	max-height: 0;
	transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
	cursor: pointer;
	display: inline-block;
	float: right;
	padding: 28px 20px;
	position: relative;
	user-select: none;
}

.header .menu-icon .navicon {
	background: green;
	display: block;
	height: 2px;
	position: relative;
	transition: background .2s ease-out;
	width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
	background: green;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
}

.header .menu-icon .navicon:before {
	top: 5px;
}

.header .menu-icon .navicon:after {
	top: -5px;
}

/* menu btn */

.header .menu-btn {
	display: none;
}

.header .menu-btn:checked ~ .menu {
	max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
	background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
	transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
	transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
	.header li {
		float: left;
	}
	.header li a {
		padding: 22px 14px;
	}
	.header .menu {
		clear: none;
		float: right;
		max-height: none;
	}
	.header .menu-icon {
		display: none;
	}
}
.logo {
	display:inline-lock;
	background-image: url(./assests/logo.png);
	background-repeat: no-repeat;
	background-size: cover;
	height: 70px;
	width: 70px;
	padding: 0px;
  }
  .brandName{
	font-size: 1em;
	font-weight: 900;
	color: rgb(68, 146, 68);
  }
  .photo1{
	background-image: url(./assests/1718215622894.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 400px;
	width: 400px;
  }
  .photo2{
	background-image: url(./assests/1721738642151.png);
	background-repeat: no-repeat;
	background-size: contain;
	width: 400px;
  }
  .photo1text{
	font-weight: 700;
	font-size: 2em;
  }
  .TitleLeadership{
	font-weight: 900;
	font-size: 3em;
	color:green;
  }
  .photo1info{
	width: 50%;
	text-align: left;
  }
  .visionTitle{
	margin-left: 100px;
	text-align: left;
	color: rgb(68, 146, 68);
	font-weight: 900;
	font-size: 3em;
  }
  .visionText{
	margin-left: 100px;
	text-align: left;
	font-size: 1.5em;
  }
  .missionTitle{
	margin-right: 100px;
	text-align: right;
	color: green;
	font-weight: 900;
	font-size: 3em;
  }
  .missionText{
	margin-right: 100px;
	text-align: right;
	font-size: 1.5em;
  }
  .item{
	width:200px;
	text-align:center;
	display:inline-block;
	background-color: transparent;
	border: 1px solid transparent;
	margin-right: 10px;
	margin-bottom: 1px;
  }
  
  #index-gallery{
	width:465px;
  }
  .leadershipHeading{
	color: rgb(68, 146, 68);
	font-size: 3em;
	font-weight: 900;
  }
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");
@mixin mQ($rem) {
  @media screen and (min-width: $rem) {
    @content;
  }
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
}

section {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-block: min(20vh, 5rem);
  text-align: center;
  width: calc(min(90rem, 90%));
  margin-inline: auto;

  p,
  h2,
  h3 {
    letter-spacing: 0.035rem;
  }

  p {
    line-height: 1.7;
    color: black;
    max-width: 70rem;
    margin: 0 auto;
    font-size: clamp(0.9rem, 0.825rem + 0.3vw, 1.2rem);
  }

  h2 {
    font-size: clamp(3rem, 2.4286rem + 2.2857vw, 5rem);
    color: black;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1rem;
  }

  .cards {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
    gap: 2rem;

    @include mQ(51rem) {
      gap: 3.5rem;
    }

    .card {
      position: relative;
      text-align: left;

      .social-icon {
        background: #111;
        padding: 1rem 1.75rem;
        display: inline-block;
        position: absolute;
        left: 0;

        i {
          color: #fff;
          font-size: 1.4rem;
        }

        &:hover i {
          color: green;
        }
      }

      img {
        filter: grayscale(100%);
        transition: 0.5s ease;

        &:hover {
          filter: grayscale(0%);
        }
      }

      .card-content-wrapper {
        margin-left: auto;
        max-width: 90%;

        .card-content {
          display: block;
          background: white;
          transition: 0.5s ease;
          padding: 4.5rem 2rem 0 3.2rem;

          > div {
            text-align: left;
          }

          h3,
          p {
            text-transform: capitalize;
          }

          h3 {
            font-size: clamp(1.15rem, 1.1071rem + 0.1714vw, 1.3rem);
            font-weight: 800;
            color: #111;
          }
          p {
            color: black;
            font-size: 0.7rem;
          }

          svg {
            display: inline-block;
            text-align: left;
            width: 60px;
            color: #003ef5;
          }
        }
      }

      /*hover states*/
      .card-img-wrapper:hover ~ .card-content-wrapper .card-content {
        background: #003ef5;

        h3,
        p,
        svg {
          color: #fff;
        }
      }

      .card-content-wrapper:hover .card-content {
        background: #003ef5;

        h3,
        p,
        svg {
          color: #fff;
        }
      }
    }
  }
}
  

.image-container {
  position: relative;
  display: inline-block; /* Keeps the container tight around the image */
  width: 100%; /* Adjust based on your layout needs */
  max-width: 1200px; /* Optional: Maximum width for the container */
  margin: 0 auto; /* Centers the container if it has a max-width */
}

.background-image {
  display: block;
  width: 100%; /* Makes sure the image fills the container */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 10px; /* Optional: Rounded corners for the image */
}

.overlay-text {
  position: absolute;
  top: 10%; /* Adjust top position */
  left: 5%; /* Adjust left position */
  width: 60%; /* Width of the overlay text area */
  color: grey; /* Text color */
  font-weight: bold;
  padding: 20px; /* Space around the text */
  box-sizing: border-box; /* Ensure padding is included in width */
  border-radius: 10px; /* Rounded corners for the overlay */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the text vertically */
}

.visionTitle {
  font-size: 24px; /* Font size for the title */
  margin-bottom: 10px; /* Space below the title */
  font-weight: bold; /* Makes the title stand out */
}

.visionText {
  font-size: 16px; /* Font size for the body text */
  line-height: 1.5; /* Improve readability */
}
